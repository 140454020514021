import { API } from "../../../config/api";
import { useAxiosPrivate } from "../axios/private";
import useAxiosPublic from "../axios/public";

interface PersonType {
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
}

interface CreateBookingUserData {
  person: {
    firstName: string;
    lastName: string;
  };
  credentials: {
    email: string;
    password: string;
  };
}

interface Person {
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
}

interface Organization {
  name: string;
  subDomain: string;
  customerEmail: string;
  currency: string;
  staffUser: {
    person: {
      firstName: string;
      lastName: string;
      gender: string;
      dateOfBirth: string;
    };
    credentials: {
      email: string;
      password: string;
    };
  };
}

interface CreateStaffData {
  person: {
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: string;
  };
  credentials: {
    email: string;
    password: string;
  };
  roles: string[];
}
interface EditStaffData {
  person: {
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: string;
  };
  role: string[];
}

export const useUserApi = () => {
  const privateInstance = useAxiosPrivate();
  const publicInstance = useAxiosPublic();

  return {
    public: {
      getAllAttendees: async () => {
        const response = await publicInstance.get(API.ATTENDEE);
        return response.data;
      },

      createAttendee: async ({
        person,
        medicalConditions,
      }: {
        person: PersonType;
        medicalConditions: string[];
      }) => {
        const response = await publicInstance.post(API.ATTENDEE, {
          person,
          medicalConditions,
        });
        return response.data;
      },
      updateAttendee: async ({
        person,
        medicalConditions,
        attendeeUuid,
      }: {
        person: PersonType;
        medicalConditions: string[];
        attendeeUuid: string;
      }) => {
        const response = await publicInstance.put(
          `${API.ATTENDEE}/${attendeeUuid}`,
          {
            person,
            medicalConditions,
          }
        );
        return response.data;
      },
      createBookingUser: async (data: CreateBookingUserData) => {
        const response = await publicInstance.post(API.USER, data);
        return response.data;
      },
      updateBookingUserProfile: async ({
        bookingUserUuid,
        bookingPerson,
      }: {
        bookingUserUuid: string;
        bookingPerson: Person;
      }) => {
        const response = await publicInstance.put(`/user/${bookingUserUuid}`, {
          person: bookingPerson,
        });
        return response.data;
      },
      updateBookingUserPassword: async ({
        bookingUserUuid,
        password,
        newPassword,
      }: {
        bookingUserUuid: string;
        password: string;
        newPassword: string;
      }) => {
        const response = await publicInstance.put(
          `/user/${bookingUserUuid}/password`,
          {
            oldPassword: password,
            newPassword,
          }
        );
        return response.data;
      },
      forgotPassword: async (email: string) => {
        const response = await publicInstance.post(API.FORGOT_PASSWORD, {
          email,
        });
        return response.data;
      },
      resetPassword: async ({
        newPassword,
        token,
      }: {
        newPassword: string;
        token: string;
      }) => {
        const response = await publicInstance.post(API.RESET_PASSWORD, {
          newPassword,
          token,
        });
        return response.data;
      },
      sendVerificationEmail: async (email: string) => {
        const response = await publicInstance.post(
          API.SEND_VERIFICATION_EMAIL,
          {
            email,
          }
        );
        return response.data;
      },
      verifyEmailCode: async ({
        email,
        verificationCode,
      }: {
        email: string;
        verificationCode: string;
      }) => {
        const response = await publicInstance.post(API.VERIFY_EMAIL, {
          email,
          verificationCode,
        });
        return response.data;
      },
      createOrganization: async (organization: Organization) => {
        const response = await publicInstance.post(
          "api/public/organization/",
          organization
        );
        return response.data;
      },
    },
    admin: {
      getStaff: async () => {
        const response = await privateInstance.get(API.STAFF_USER);
        return response.data;
      },
      getStaffUserById: async (id: string) => {
        const response = await privateInstance.get(`${API.STAFF_USER}/${id}`);
        return response.data;
      },
      getAllBookingUsers: async () => {
        const response = await privateInstance.get(API.BOOKING_USER);
        return response.data;
      },
      getBookingUserById: async (id: string) => {
        const response = await privateInstance.get(`${API.BOOKING_USER}/${id}`);
        return response.data;
      },
      getAttendeesByUserId: async (id: string) => {
        const response = await privateInstance.get(
          `${API.GET_BOOKING_USERS}${id}/attendee`
        );
        return response.data;
      },
      updateStaffUserProfile: async ({
        staffUserUuid,
        staffPerson,
      }: {
        staffUserUuid: string;
        staffPerson: Person;
      }) => {
        const response = await privateInstance.put(
          `/user/staff-user/${staffUserUuid}`,
          {
            person: staffPerson,
          }
        );

        return response.data;
      },
      updateStaffUserPassword: async ({
        staffUserUuid,
        password,
        newPassword,
      }: {
        staffUserUuid: string;
        password: string;
        newPassword: string;
      }) => {
        const response = await privateInstance.put(
          `/user/staff-user/${staffUserUuid}/password`,
          {
            oldPassword: password,
            newPassword,
          }
        );
        return response.data;
      },
      getLoggedInUser: async () => {
        const response = await privateInstance.get("/user");
        return response.data;
      },
      getUser: async (token: string) => {
        const response = await privateInstance.get("/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      },
      createAttendeeForUser: async ({
        person,
        medicalConditions,
        userUuid,
      }: {
        person: {
          firstName: string;
          lastName: string;
          gender: string;
          dateOfBirth: string;
        };
        medicalConditions: string[];
        userUuid: string;
      }) => {
        const response = await privateInstance.post(
          `${API.BOOKING_USER}/${userUuid}/attendee`,
          {
            person,
            medicalConditions,
          }
        );
        return response.data;
      },
      updateAttendeeForUser: async ({
        person,
        medicalConditions,
        attendeeUuid,
        userUuid,
      }: {
        person: PersonType;
        medicalConditions: string[];
        attendeeUuid: string;
        userUuid: string;
      }) => {
        const response = await privateInstance.put(
          `${API.BOOKING_USER}/${userUuid}${API.ATTENDEE}/${attendeeUuid}`,
          {
            person,
            medicalConditions,
          }
        );
        return response.data;
      },
      getAttendee: async () => {
        const response = await privateInstance.get(API.ATTENDEE);
        return response.data;
      },
      createStaff: async (data: CreateStaffData) => {
        const response = await privateInstance.post(API.STAFF_USER, data);
        return response.data;
      },
      editStaff: async ({
        data,
        userId,
      }: {
        data: EditStaffData;
        userId: string;
      }) => {
        const response = await privateInstance.put(
          API.STAFF_USER_EDIT + `/${userId}`,
          data
        );
        return response.data;
      },
    },
  };
};
