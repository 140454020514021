import axios from "axios";

export const API_BASE_URL = process.env.VITE_BACKEND_URL;

export const apiInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    "Content-type": "application/json",
  },
});

export const apiPrivateInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    "Content-type": "application/json",
  },
});

export const apiPrivateInstanceMedia = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    "Content-type": "image/png",
    Accept: "image/jpeg, image/jpg",
  },
});

export const API = {
  SIGN_IN: "/login",
  AUTH: "/api/auth",
  SIGN_UP: "/user/staff-user",
  GET_ACTIVITIES: "/activity",
  GET_ACTIVITY_DETAILS: "/activity",
  GET_BOOKINGS: "/booking",
  GET_PUBLIC_BOOKINGS: "/booking",
  GET_TRANSACTIONS: "/booking/transaction",
  GET_SESSIONS_FOR_ACTIVITY: "/activity",
  VENUE: "/venue",
  BOOKING_USER: "/user/booking-user",
  STAFF_USER: "/user/staff-user",
  STAFF_USER_EDIT: "/admin/staff-user",
  SESSIONS: "/session",
  PUBLIC_SESSIONS: "/session",
  USER_REGISTER: "/register",
  GET_BOOKING_USERS: "/user/booking-user/",
  BOOKING_TXN: "/booking/transaction",
  ADMIN_BOOKING: "/booking/",
  ORGANIZATION: "/organization",
  ADMIN_ORGANIZATION: "/organization",
  PUBLIC_ORGANIZATION: "/organization",
  SOCIAL_LINK: "/social-link/",
  DISCOUNT_CODE: "/discount-code",
  // PUBLIC
  USER: "/user",
  SEND_VERIFICATION_EMAIL: "/send-verification-email",
  VERIFY_EMAIL: "/verify-email",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  ATTENDEE: "/attendee",
  NOTIFICATION: "/notification",
};
